import styled from 'styled-components';

export const TitleLeft = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes.xl};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-weight: bold;
  text-align: left;
  padding-bottom: 1rem;
  align-self: flex-start;
`;
