import styled from 'styled-components';

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 32px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Input = styled.input`
  width: 100%;
  height: 55px;
  padding: 16px;
  box-sizing: border-box;
  border: 2px solid ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${({ theme }) => theme.fontSizes.l};
  font-weight: bold;
  outline: none;
`;

export const Select = styled.select`
  width: 100%;
  height: 60px;
  padding: 16px;
  box-sizing: border-box;
  border: 2px solid ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${({ theme }) => theme.fontSizes.l};
  font-weight: bold;
  outline: none;
`;

export const ErrorMessage = styled.div`
  color: red;
  font-size: ${({ theme }) => theme.fontSizes.m};
  text-align: center;
`;
