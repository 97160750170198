import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import styled from 'styled-components';
import WelcomeScreen from './screens/WelcomeScreen';
import AddressInputScreen from './screens/AddressInputScreen';
import VideoInstructionScreen from './screens/VideoInstructionScreen';
import CustomVideoInstructionScreen from './screens/CustomVideoInstructionScreen';
import CustomDefaultVideoInstructionScreen from './screens/CustomDefaultVideoInstructionScreen';
import VideoUploadScreen from './screens/VideoUploadScreen';
import ThankYouScreen from './screens/ThankYouScreen';
import AdditionalInfoScreen from './screens/AdditionalInfoScreen';
import ProtectedRoute from './ProtectedRoute';
import SelfServeScreen from './screens/SelfServeScreen';

const AppContainer = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
`;

function App() {
  return (
    <Router>
      <AppContainer>
        <Routes>
          <Route path='/' element={<WelcomeScreen />} />
          <Route
            path='/address-input'
            element={
              <ProtectedRoute>
                <AddressInputScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path='/video-instructions'
            element={
              <ProtectedRoute>
                <VideoInstructionScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path='/custom-video-instructions'
            element={
              <ProtectedRoute>
                <CustomVideoInstructionScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path='/custom-default-video-instructions'
            element={
              <ProtectedRoute>
                <CustomDefaultVideoInstructionScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path='/video-upload'
            element={
              <ProtectedRoute>
                <VideoUploadScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path='/additional-info'
            element={
              <ProtectedRoute>
                <AdditionalInfoScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path='/thank-you'
            element={
              <ProtectedRoute>
                <ThankYouScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path='/self-serve/:organisation'
            element={<SelfServeScreen />}
          />
          <Route path='*' element={<Navigate to='/' replace />} />
        </Routes>
      </AppContainer>
    </Router>
  );
}

export default App;
