import styled from 'styled-components';

export const Form = styled.form`
  width: 100%;
  max-width: 400px;
  padding-top: 16px;
`;

export const Input = styled.input`
  width: 100%;
  box-sizing: border-box;

  height: 55px;
  outline: 0px solid
    ${({ hasBlackBackground }) =>
      hasBlackBackground
        ? ({ theme }) => theme.colors.grey
        : ({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${({ theme }) => theme.fontSizes.l};
  font-weight: bold;
  margin-bottom: 16px;
  padding: 16px;
  border: 2px solid ${({ theme }) => theme.colors.black};
`;

export const FindMyLocationTextWrapper = styled.div`
  width: 100%;
  text-align: center;
`;
