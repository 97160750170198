import styled from 'styled-components';
import Paragraph from '../../components/Paragraph';

export const ProgressBarContainer = styled.div`
  margin-top: 16px;
  width: 100%;
  max-width: 400px;
`;

export const ProgressBarOuter = styled.div`
  background-color: ${({ theme }) => theme.colors.black};
  border-radius: 9999px;
  height: 10px;
`;

export const ProgressBarInner = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryBackgroundColor};
  color: ${({ theme }) => theme.colors.primaryColor};
  border-radius: 9999px;
  height: 100%;
  width: ${({ progress }) => progress}%;
  transition: width 0.2s;
`;

export const ProgressText = styled.p`
  text-align: center;
  margin-top: 8px;
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const SelectedVideo = styled.div`
  margin: 0;
  position: relative;
  height: 200px;
  width: calc(50% - 4px);
  display: flex;
  align-items: center;
  padding: 0px 8px;
  box-sizing: border-box;

  img {
    width: 100%;
    object-fit: contain;
  }
`;

export const RedoContainer = styled.div`
  padding-top: 24px;
  width: 250px;
`;

export const TextArea = styled.textarea`
  width: 100%;
  box-sizing: border-box;

  height: 110px;
  outline: 0px solid
    ${({ hasBlackBackground }) =>
      hasBlackBackground
        ? ({ theme }) => theme.colors.grey
        : ({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${({ theme }) => theme.fontSizes.l};
  font-weight: bold;
  padding: 16px;
  border: 2px solid ${({ theme }) => theme.colors.black};
`;

export const AdditionalMediaButtonContainer = styled.div`
  width: 100%;
`;

export const Divider = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  height: 16px;
  width: 100vw;
  background-color: ${({ theme }) => theme.colors.lightGrey};
`;

export const SubHeadingParagraph = styled(Paragraph)`
  margin-top: -8px !important;
`;

export const ThumbnailGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: flex-start;
  padding-bottom: 16px;
`;
