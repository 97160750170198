import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ProgressHeader from '../../components/ProgressHeader';
import RedoButton from '../../components/RedoButton';

import ScrollToTop from '../../components/ScrollToTop';
import FullWidthButton from '../../components/FullWidthButton';
import ContentContainer from '../../components/ContentContainer';
import { SelectedVideo, RedoContainer, StyledMainContainer } from './styled';
import * as Analytics from '../../helpers/analytics';

const VideoUploadScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state || {};

  useEffect(() => {
    if (state.video) {
      const videoElement = document.getElementById('videoPreview');
      videoElement.preload = 'metadata';
      videoElement.playsInline = true;
      videoElement.addEventListener('loadedmetadata', () => {
        // Move video forward slightly (0.1 seconds) to show first real frame
        videoElement.currentTime = 0.1;
      });
      videoElement.src = URL.createObjectURL(state.video);
    }
  }, [state.video]);

  const handleSubmit = useCallback(async () => {
    navigate('/additional-info', {
      state,
      replace: true,
    });
  }, []);

  const handleRedo = () => {
    Analytics.trackEvent('show-me-redo-video', {});
    if (state.customInstructions) {
      navigate('/custom-video-instructions', { state, replace: true });
    } else if (state.customDefaultVideoInstructions) {
      navigate('/custom-default-video-instructions', { state, replace: true });
    } else {
      navigate('/video-instructions', { state, replace: true });
    }
  };

  return (
    <>
      <ScrollToTop />
      <ProgressHeader step={1} />
      <StyledMainContainer>
        <ContentContainer>
          <SelectedVideo>
            <video id='videoPreview' controls />
          </SelectedVideo>
          <RedoContainer>
            <RedoButton onClick={handleRedo} width='100%'>
              {t('common.redo')}
            </RedoButton>
          </RedoContainer>
        </ContentContainer>
      </StyledMainContainer>
      <FullWidthButton type='button' onClick={handleSubmit}>
        {t('common.next')}
      </FullWidthButton>
    </>
  );
};

export default VideoUploadScreen;
