import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { HeaderContainer } from './styled';
import { Stepper } from 'react-form-stepper';
import { ThemeContext } from '../../helpers/ThemeProvider';

import './styles.css';

const Header = ({ step }) => {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);

  return (
    <HeaderContainer>
      <Stepper
        steps={[
          { label: t('welcome.steps.locate') },
          { label: t('welcome.steps.film') },
          { label: t('welcome.steps.send') },
        ]}
        activeStep={step}
        connectorStyleConfig={{
          activeColor: theme.colors.black,
          disabledColor: theme.colors.black,
          completedColor: theme.colors.black,
          size: 2,
        }}
        styleConfig={{
          activeBgColor: theme.colors.primaryBackgroundColor,
          activeTextColor: theme.colors.primaryColor,
          inactiveBgColor: theme.colors.white,
          inactiveTextColor: theme.colors.black,
          completedBgColor: theme.colors.white,
          completedTextColor: theme.colors.black,
        }}
        className='stepper-class'
        stepClassName='step-class'
      />
    </HeaderContainer>
  );
};

Header.propTypes = {
  step: PropTypes.number.isRequired,
};

export default Header;
