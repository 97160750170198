import { createContext } from 'react';
import defaultTheme from '../theme';
import { useLocalStorage } from 'usehooks-ts';

const ThemeContext = createContext();

const THEME_STORAGE_KEY = 'flowframe_theme';

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useLocalStorage(THEME_STORAGE_KEY, defaultTheme);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeProvider, ThemeContext };
