import './assets/fonts/index.css';
import { css } from 'styled-components';
import { mediaQueries } from './helpers';

export default {
  breakpoints: {
    xs: 480,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1344,
    xxl: 1600,
    hd: 1920,
  },
  colors: {
    black: '#151515',
    white: '#ffffff',
    whiteGrey: '#fbfbfb',
    offWhite: '#f3f3f3',
    yellow: '#fff308',
    lightOrange: '#ffb02c',
    orange: '#ffa800',
    red: '#ff1b17',
    darkRed: '#e60400',
    blue: '#2248c1',
    darkBlue: '#042281',
    lightBlue: '#2248c11a',
    grey: '#b8b8b8',
    darkGrey: '#737373',
    lightGrey: '#e7e7e7',
    transparentGrey: '#232f2f2f',
    green: '#3dca29',
    lightGreen: '#48c34d30',
    primaryBackgroundColor: '#fff308',
    primaryColor: '#151515',
  },
  fonts: {
    default: 'FSMillbankWeb-Regular',
    italic: 'FSMillbankWeb-Italic',
    light: 'FSMillbankWeb-Light',
    lightItalic: 'FSMillbankWeb-LightItalic',
    bold: 'FSMillbankWeb-Bold',
    boldItalic: 'FSMillbankWeb-BoldItalic',
    heavy: 'FSMillbankWeb-Heavy',
    heavyItalic: 'FSMillbankWeb-HeavyItalic',
  },
  fontSizes: {
    xs: '12px',
    s: '14px',
    default: '16px',
    m: '18px',
    l: '20px',
    xl: '24px',
    xxl: '30px',
    xxxl: '32px',
    massive: '40px',
    ultimate: '48px',
  },
  shadows: {
    default: '0 4px 4px 0 rgba(0, 0, 0, 0.3)',
  },
  mixins: {
    flexBetween: css`
      display: flex;
      align-items: center;
      justify-content: space-between;
    `,
    flexColumn: css`
      display: flex;
      flex-direction: column;
    `,
    flexCenter: css`
      display: flex;
      align-items: center;
    `,
    flexFullCenter: css`
      display: flex;
      align-items: center;
      justify-content: center;
    `,
  },
  media: mediaQueries,
  logoMaxWidth: '140px',
  logoMaxHeight: '60px',
};
