import styled from 'styled-components';
import MainContainer from '../../components/MainContainer';

export const ProgressBarContainer = styled.div`
  margin-top: 16px;
  width: 100%;
  max-width: 400px;
`;

export const ProgressBarOuter = styled.div`
  background-color: ${({ theme }) => theme.colors.black};
  border-radius: 9999px;
  height: 10px;
`;

export const ProgressBarInner = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryBackgroundColor};
  color: ${({ theme }) => theme.colors.primaryColor};
  border-radius: 9999px;
  height: 100%;
  width: ${({ progress }) => progress}%;
  transition: width 0.2s;
`;

export const ProgressText = styled.p`
  text-align: center;
  margin-top: 8px;
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const SelectedVideo = styled.p`
  margin: 0;
  position: relative;
  height: calc(100vh - 312px);
  display: flex;
  align-items: center;
  padding: 16px;

  video {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const RedoContainer = styled.div`
  padding: 16px;
  width: calc(100% - 32px);
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
`;

export const StyledMainContainer = styled(MainContainer)`
  background-color: ${({ theme }) => theme.colors.black};
  position: relative;
  height: calc(100vh - 120px);
  overflow: hidden;
  padding: 0;
`;

export const ErrorText = styled.p`
  color: ${({ theme }) => theme.colors.white};
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 12px;
  border-radius: 4px;
  width: 80%;
  white-space: pre-line;
`;
