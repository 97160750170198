import { useState, useRef, useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ProgressHeader from '../../components/ProgressHeader';
import MainContainer from '../../components/MainContainer';
import FullWidthButton from '../../components/FullWidthButton';
import ScrollToTop from '../../components/ScrollToTop';
import VideoUpload from '../../components/VideoUpload';

import * as Analytics from '../../helpers/analytics';

const CustomDefaultVideoInstructionScreen = () => {
  const { t } = useTranslation();
  const [videos, setVideos] = useState([]);
  const [videoThumbnails, setVideoThumbnails] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state || {};
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (videos.length > 0 && videoThumbnails.length > 0) {
      const video = videos[0];
      const thumbnail = videoThumbnails[0];
      navigate('/video-upload', {
        state: { ...state, video, thumbnail },
        replace: true,
      });
    }
  }, [videos, videoThumbnails]);

  const handleAddVideoClick = useCallback(() => {
    Analytics.trackEvent('show-me-record-video', {});
    fileInputRef.current.click();
  }, []);

  return (
    <>
      <ScrollToTop />
      <ProgressHeader step={1} />
      <MainContainer>
        <div
          dangerouslySetInnerHTML={{
            __html: state.customDefaultVideoInstructions,
          }}
        />
      </MainContainer>
      <VideoUpload
        setVideos={setVideos}
        setVideoThumbnails={setVideoThumbnails}
        inputRef={fileInputRef}
      />
      <FullWidthButton type='button' onClick={handleAddVideoClick}>
        {t('common.next')}
      </FullWidthButton>
    </>
  );
};

export default CustomDefaultVideoInstructionScreen;
