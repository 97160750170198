import styled from 'styled-components';

export const HeaderContainer = styled.header`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 8px;
`;

export const Logo = styled.img`
  max-width: ${({ theme }) => theme.logoMaxWidth};
  max-height: ${({ theme }) => theme.logoMaxHeight};
  width: auto;
  height: auto;
`;
