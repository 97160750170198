import styled from 'styled-components';
import { Arrow } from '../../assets/icons';

export const ArrowRight = styled(Arrow)`
  height: 20px;
  .fill-selector {
    fill: ${({ theme }) => theme.colors.primaryColor};
  }
  opacity: ${({ disabled }) => (disabled ? 0.65 : 1)};
`;

export const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primaryBackgroundColor};
  color: ${({ theme }) => theme.colors.primaryColor};
  font-size: ${({ theme }) => theme.fontSizes.xl};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-weight: bold;
  padding: 14px 24px;
  width: 100%;
  border: none;
  border-top: solid;
  border-top-width: 2px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 1;
  position: sticky;
  bottom: 0;
`;

export const TextWrapper = styled.span`
  flex-grow: 1;
  text-align: left;
  opacity: ${({ disabled }) => (disabled ? 0.65 : 1)};
`;
