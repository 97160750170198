import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ProgressHeader from '../../components/ProgressHeader';
import MainContainer from '../../components/MainContainer';
import FullWidthButton from '../../components/FullWidthButton';
import ScrollToTop from '../../components/ScrollToTop';
import MarkdownContent from '../../components/MarkdownContent';

const CustomVideoInstructionScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state || {};

  const handleNext = () => {
    if (state.customDefaultVideoInstructions) {
      navigate('/custom-default-video-instructions', { state, replace: true });
    } else {
      navigate('/video-instructions', { state, replace: true });
    }
  };

  return (
    <>
      <ScrollToTop />
      <ProgressHeader step={0} />
      <MainContainer>
        <MarkdownContent content={state.customInstructions} />
      </MainContainer>
      <FullWidthButton type='button' onClick={handleNext}>
        {t('common.next')}
      </FullWidthButton>
    </>
  );
};

export default CustomVideoInstructionScreen;
